@import "src/components/ui.module";

.invite-wrapper {
  position: absolute;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 450px;

  background: var(--color-gray-light);
  justify-content: center;
}

.invite {
  width: 400px;

  margin: 0 auto;

  composes: ui-box-with-shadow;
}

.invite__logo {
  height: 85px;

  border-top-left-radius: calc(var(--global-radius) * 2);
  border-top-right-radius: calc(var(--global-radius) * 2);

  background: var(--color-petrol) url("images/logo.png") center center no-repeat;
  background-size: 200px auto;
}

.invite__title {
  margin-bottom: var(--distancer);

  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bolder);

  composes: ui-h3;
}

.invite__form {
  padding: var(--distancer) calc(var(--distancer) * 2);
}

.invite__submit-wrapper {
  margin-top: var(--distancer);

  text-align: center;
}
