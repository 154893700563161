@import "src/components/ui.module.scss";

:root {
  --menu__link--padding: 0 var(--distancer) 0 calc(var(--distancer) / 2);
  --menu__link--header-padding: 0 var(--distancer);
  --menu__link--header-height: 90px;
  --menu__link--submenu-padding: 0 var(--distancer) 0 calc(var(--distancer) / 2);
}

.sidemenu {
  position: fixed;
  z-index: 101;

  display: flex;

  overflow: hidden;

  flex-direction: column;

  width: 220px;
  height: 100vh;

  min-height: 100vh;

  transform: translate3d(0, 0, 0);

  background-color: var(--color-petrol-light);
}

.sidemenu__footer {
  padding: calc(var(--distancer) / 2);

  text-align: center;

  color: #a7b1c2;
}

.sidemenu__footer a {
  color: #a7b1c2;
}

.sidemenu__footer a:hover {
  color: var(--color-white);
}

@media print {
  .sidemenu {
    display: none;
  }
}

/**
 * Menu - ul
 */

.menu {
  flex: 1;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;
}

/**
 * Menu item - li
 */
.menu-item {
  position: relative;

  display: block;

  margin: 0;
  padding: 0;

  list-style-type: none;
}

.menu-scollbar__thumb {
  border-radius: var(--global-radius);
  background-color: color(var(--color-primary) a(50%));
}

/**
 * Menu item link - ul li a
 */

.menu-link {
  display: block;

  overflow: hidden;

  height: 70px;

  padding: var(--menu__link--padding);

  vertical-align: middle;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: #a7b1c2;

  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);

  line-height: 70px;

  &:hover,
  &:focus {
    text-decoration: none;

    color: var(--color-white);

    background-color: var(--color-petrol-dark);
  }
}

.menu-link.active {
  color: var(--color-white);
  background-color: var(--color-primary);
}

/**
 * Menu item icon - ul li a i
 */

.menu-icon {
  width: calc(var(--distancer) / 2);
  margin-right: calc(var(--distancer) / 2);

  text-align: center;
}

/**
 * Submenu nav items - ul li.submenu
 */

.submenu {
  margin: 0;
  padding: 0;
}

.submenu-item .menu-link {
  padding: var(--menu__link--submenu-padding);
}

.submenu-item .menu-link.active {
  background-color: var(--color-petrol-dark);
}

.submenu-item .menu-icon {
  display: inline-block;

  width: 17px;
  height: 70px;

  vertical-align: middle;

  background: transparent url("./_images/submenu.png") 0 0 no-repeat;
}

.submenu-item:last-child .menu-icon {
  background: transparent url("./_images/submenu-end.png") 0 0 no-repeat;
}

.submenu-item .menu-link.active .menu-icon {
  background: transparent url("./_images/submenu-active.png") 0 0 no-repeat;
}

.submenu-item:last-child .menu-link.active .menu-icon {
  background: transparent url("./_images/submenu-end-active.png") 0 0 no-repeat;
}

.submenu-item-beta span::after {
  position: absolute;
  top: -5px;

  content: "BETA";

  font-size: 10px;
}

/**
 * Menu header - ul li
 */

.menu-header .menu-link {
  height: var(--menu__link--header-height);
  padding: var(--menu__link--header-padding);

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);
}

.menu-header__logo {
  display: block;

  height: 100%;

  background-image: url("./_images/logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.menu-header .menu-link :global(.fa) {
  margin-top: 5px;
  margin-right: 0;

  font-size: 30px;
}

/**
 * Specifics
 */

.icon-exchange {
  position: absolute;
  top: 50%;
  right: 15px;

  transform: translateY(-50%);

  font-size: 16px;
}
