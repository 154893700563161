@import "src/components/ui.module";

.login-wrapper {
  position: absolute;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 450px;

  background: var(--color-gray-light);
  justify-content: center;
}

.login {
  width: 400px;

  margin: 0 auto;

  composes: ui-box-with-shadow;
}

.login__logo {
  height: 85px;

  border-top-left-radius: calc(var(--global-radius) * 2);
  border-top-right-radius: calc(var(--global-radius) * 2);

  background: var(--color-petrol) url("images/logo.png") center center no-repeat;
  background-size: 200px auto;
}

.login__title {
  margin-bottom: var(--distancer);

  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bolder);

  composes: ui-h3;
}

.login__form {
  padding: var(--distancer) calc(var(--distancer) * 2);
}

.login__error {
  margin-bottom: calc(var(--distancer) / 2);
}

.login_field {
  margin-bottom: calc(var(--distancer) / 2);
}

.login__forgot-password {
  display: block;

  margin-top: calc(-1 * var(--distancer) / 4);
  margin-bottom: var(--distancer);

  composes: ui-link--muted;
}

.login__forgot-password,
.login__remember-me {
  font-size: 12px;
}

.login__submit-wrapper {
  margin-bottom: var(--distancer);

  text-align: center;
}

.login__multifactor-auth-app-verification-container {
  margin-bottom: var(--distancer);
}

.login__multifactor-auth-app-verification-input {
  display: flex;
  justify-content: center;
}

.login__multifactor-auth-app-error {
  margin-top: 1rem;
  color: var(--color-red-main);
}

.login__multifactor-auth-app-guide ol {
  padding: 0 0 1em;
}

.login__multifactor-auth-app-link {
  display: block;
  margin-top: 0.2rem;
  margin-bottom: var(--distancer);
  text-align: center;
  composes: ui-link--muted;
}
